import React, { useEffect } from 'react';
import { FloatingHelper, ToggleSwitch, FormField, EmptyState, CustomModalLayout, TextButton, Heading, Modal, MessageModalLayout, Text, Card, FeatureList, Box, Page, InputArea, Button, Cell, Layout, Input, WixDesignSystemProvider, Image, PageSection, Badge, MarketingLayout, AnnouncementModalLayout, Tooltip, PulseAnimation } from '@wix/design-system';
import "@wix/design-system/styles.global.css";
import * as Icons from '@wix/wix-ui-icons-common';
import './App.css';
import { ReactTagManager } from 'react-gtm-ts';

const tagManagerArgs = {
  code: 'GTM-WZQPMC7'
}

function App() {
  ReactTagManager.init(tagManagerArgs)
  const [isHighlighted, setIsHighlighted] = React.useState(false);
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isOpening, setIsOpening] = React.useState(false);
  const [shownSettingsModal, setShownSettingsModal] = React.useState(false);
  const [merchantId, setMerchantId] = React.useState("");
  const [accessCode, setAccessCode] = React.useState("");
  const [workingKey, setWorkingKey] = React.useState("");
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: ""
    }
  });

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getSettings();
  }, [])

  const token = new URLSearchParams(window.location.search).get('token');
  const instance = new URLSearchParams(window.location.search).get('instance')

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=e3eb60e5-d247-4eb9-907d-f51647ab566e&redirectUrl=https://certifiedcode.editorx.io/labels/_functions/@certifiedcode/base-backend/auth`;
  }

  // if (!instance) {
  //   return null
  // }

  const BASE_URL = `https://certifiedcode.editorx.io/labels/_functions`

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash
          });
        }
      })
      .catch(() => {

      })
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
      })
      .catch(() => {

      })
  }

  function setSettings() {
    // if (isUpgraded) {
    if (!open) {
      if (merchantId === "" || accessCode === "" || workingKey === "") {
        setShownSettingsModal(true);
        return;
      }
    }
    const account = !open === true ? {
      merchantId,
      accessCode,
      workingKey
    } : {}
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        "Authorization": instance || ""
      },
      body: JSON.stringify({
        isEnabled: !open,
        ...account
      })
    })
      .then(() => {
        setShownSettingsModal(false);
        setIsOpening(false);
        setOpen(!open);
        setMerchantId("");
        setAccessCode("");
        setWorkingKey("");
      })
      .catch(() => {

      })
    // } else {
    //   setIsUpgradeModalOpen(true)
    // }
  }

  function getSettings() {
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        setIsOpening(false);
        setOpen(data.isEnabled);
      })
      .catch(() => {

      })
  }

  const isPremiumApp = true

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height='100vh'>
        <Page.Header
          title={<Box gap={"SP2"} alignItems="middle">
            <Heading>Labels</Heading>
          </Box>}
          actionsBar={
            <Box gap={"SP2"} direction="horizontal">
              {isPremiumApp ? <Button skin="premium" disabled={isUpgraded} prefixIcon={<Icons.PremiumFilled />} onClick={() => setIsUpgradeModalOpen(true)}>Accept Online Payments</Button>
                : <></>}
            </Box>
          }
        />
        <Page.Content>
          <Modal
            isOpen={shownSettingsModal}
            onRequestClose={() => setShownSettingsModal(false)}
            shouldCloseOnOverlayClick
            screen="desktop"
          >
            <CustomModalLayout
              primaryButtonText="Save"
              secondaryButtonText="Cancel"
              onCloseButtonClick={() => setShownSettingsModal(false)}
              secondaryButtonOnClick={() => setShownSettingsModal(false)}
              primaryButtonOnClick={() => setSettings()}
              removeContentPadding
              title="Connect to CCAvenue"
              content={
                <Page>
                  <Page.Content>
                    <Box marginTop={5} display="block">
                      <Layout>
                        <Cell span={12}>
                          <Box direction='vertical' gap="SP2">
                            <FormField label="Merchant ID">
                              <Input clearButton value={merchantId} onChange={(e) => setMerchantId(e.target.value)} />
                            </FormField>
                            <FormField label="Access Code">
                              <Input clearButton value={accessCode} onChange={(e) => setAccessCode(e.target.value)} />
                            </FormField>
                            <FormField label="Working Key">
                              <Input clearButton value={workingKey} onChange={(e) => setWorkingKey(e.target.value)} />
                            </FormField></Box>
                        </Cell>
                      </Layout>
                    </Box>
                  </Page.Content>
                </Page>
              }
            />
          </Modal>
          <Modal
            isOpen={isUpgradeModalOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setIsUpgradeModalOpen(false)}
          >
            <AnnouncementModalLayout
              theme="premium"
              title="Accept online payments without limits"
              primaryButtonText="Upgrade"
              linkText="Learn more"
              primaryButtonOnClick={() => { window.open(`https://www.wix.com/apps/upgrade/${"e3eb60e5-d247-4eb9-907d-f51647ab566e"}?appInstanceId=${(instanceData as any)['instance']['instanceId']} `) }}
              linkOnClick={() => { window.open('https://support.certifiedcode.us/en/articles/7953534-getting-started-ccavenue-pay-button#h_b92f8de467') }}
              onCloseButtonClick={() => setIsUpgradeModalOpen(false)}
            >
              <Text>
                Accept online payments via CCAvenue Pay Button. Upgrade to accept more payments.
              </Text>
            </AnnouncementModalLayout>
          </Modal>
          <Modal
            isOpen={isModalOpen}
            screen="desktop"
          ><MessageModalLayout
              primaryButtonText="Refresh"
              primaryButtonOnClick={() => window.location.reload()}
              title="Ops, something went wrong"
              content={
                <Text>Please refresh this page, or contact support.</Text>
              }
            />
          </Modal>
          <Layout>
            <Cell span={12}>
              <Card>
                <Card.Header
                  title={<Box gap={"SP2"}><Heading size="medium" as="h2">Accept Online Payments</Heading>{isUpgraded ? <Badge size="tiny" skin={"success"}>{"Ready"}</Badge> : <FloatingHelper
                    target={<Badge size="tiny" skin={"danger"}>{"Limited"}</Badge>}
                    content={
                      <FloatingHelper.Content
                        title="Don't forget to upgrade to accept more than 3 payments a month!"
                        body="In Starter (free) plan, only 3 payments per month are allowed. Upgrade to accept more payments."
                      />
                    }
                    placement="right"
                  />}</Box>}
                  suffix={
                    <ToggleSwitch
                      onChange={() => { setSettings() }}
                      size="medium"
                      checked={open}
                      disabled={isOpening}
                    />
                  }
                />
                <Card.Subheader title={<Text size='small'>Pay Button can be placed everywhere on your site via Editor, and supports custom amount or pre-defined amount payment. <b>Note: Pay Button does not integrate with Wix eCommerce.</b></Text>} suffix={
                  <Button skin='light' size="small" onClick={() => { setIsHighlighted(true) }}>Explore advanced integration</Button>
                } />
                <Card.Content>
                  {(open && isUpgraded) ?
                    <EmptyState
                      theme="section"
                      title="Great! You're ready to accept online payments."
                      subtitle="To change your CCAvenue account, please turn off “Accept Online Payments”, then turn it on again and fill in the information."
                    >
                      <TextButton prefixIcon={<Icons.ExternalLink />} as={"a"} href={(instanceData as any)['site'] ? (instanceData as any)['site']['url'] : ""} target={"_blank"}>Open Live Site</TextButton>
                    </EmptyState> : <></>}
                </Card.Content>
              </Card>
            </Cell>
            <Cell>
              <PageSection title="Suggested for you" showDivider />
            </Cell>
            <Cell span={12}>
              <Card>
                <PulseAnimation active={isHighlighted} color="B10" borderRadius="18px" fluid={true}>
                  <MarketingLayout
                    title="Accept Online Payments"
                    description="With Certified Code Business Solutions, you can accept online payments from your customers via Wix Stores, Bookings and more."
                    actions={<Box direction="horizontal" gap="SP1">
                      <Button size="small" skin="premium" as='a' href='https://certifiedcode.in/pricing' target='_blank'>See Pricing</Button>
                      <Button size="small" skin="inverted" onClick={() => { window.Intercom("showNewMessages", "I would like to accept payments with CCAvenue on Wix Stores and other Wix apps.") }}>Talk with sales</Button>
                    </Box>}
                    size="tiny"
                    badge={<Badge size="small">IN APP PURCHASE</Badge>}
                    image={
                      <Box width="100%" align="right">
                        <Image
                          width="120px"
                          src="https://www.wix-style-react.com/storybook/generic_upgrade.svg"
                          transparent
                        />
                      </Box>
                    }
                  />
                </PulseAnimation>
              </Card>
            </Cell>
            <Cell span={12}>
              <Card>
                <MarketingLayout
                  title="Contact support"
                  description="Need help? Talk with our team to get answers to your questions."
                  actions={<Button size="small" onClick={() => { window.Intercom("showNewMessages", "I need help with CCAvenue Pay Button.") }}>Live Chat</Button>}
                  size="tiny"
                  hiddenBadge
                  alignItems="stretch"
                  image={
                    <Box width="100%" align="right">
                      <Image
                        width="120px"
                        src="https://www.wix-style-react.com/storybook/PromotionalBookingsUpgrade.svg"
                        transparent
                      />
                    </Box>
                  }
                />
              </Card>
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
